import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs';
import JotformEmbed from 'react-jotform-embed'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import htmlSerializer from '../utils/htmlSerializer';
import LayoutNew from "../components/layout-new";
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const PageRequestCaseStudy = () => {
  const data = useStaticQuery(graphql`
    query PageRequestCaseStudyQuery {
      prismicRequestCaseStudyPage {
        _previewable
        data {
          request_case_study_page_title
          request_case_study_page_short_description
          request_case_study_page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          request_case_study_page_content {
            richText
          }
          jotform_embed_url
        }
      }
    }
  `)

  const doc = data.prismicRequestCaseStudyPage
  const imgHero = getImage(doc.data.request_case_study_page_hero_background)
  const imgSrc = getSrc(doc.data.request_case_study_page_hero_background)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.request_case_study_page_title}
        description={doc.data.request_case_study_page_short_description}
        image={imgSrc}
      />
      <HeroSection
        title={doc.data.request_case_study_page_title}
        imgSrc={imgHero}
      />

      <div className="bg-site-green-light py-12 md:py-24">
        {doc.data.request_case_study_page_content
          ?
          <div className="w-11/12 lg:w-10/12 max-w-screen-md text-center mx-auto">
            <RichText
              render={doc.data.request_case_study_page_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }

        {doc.data.jotform_embed_url
          ?
          <div className="w-11/12 lg:w-10/12 max-w-screen-md mt-16 mx-auto">
            <div className="relative bg-white flex flex-col items-center border-2 border-site-gray-dark rounded-3xl px-8 pt-4 pb-16 shadow-lg">
              <JotformEmbed src={doc.data.jotform_embed_url} />
            </div>
          </div>
          :
          null
        }
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageRequestCaseStudy)
